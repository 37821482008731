import { signupDetail } from '@/service/enroll-activity/index';

export default{
    data(){
        return{
            signupDetail: {},
            signupId: null
        }
    },
    mounted(){
        this.signupId = this.$route.query.signupId
        this.getDetail()
    },
    methods:{
        // 获取活动详情
        async getDetail(){
            try {
                const res = await signupDetail({ mainId: this.signupId })
                this.signupDetail = res
            } catch (error) {
                setTimeout(() =>{
                    this.$router.go(-1)
                }, 2500)
            }
        },
        onOk(){
            let { signupDetail } = this;
            this.$router.push({
                path: '/enroll-activity/add-enroll',
                query:{
                    signupId: this.signupId,
                    id: signupDetail.id
                }
            })
        }
    }
}